const indexMixin = (index, options, exportData) => ({
  data() {
    return {
      model: "",
      showDialog: false,
      editing: false,
      defaultParams: {},
      params: {},
      pagination: {
        last_page: 1,
        total: 0
      },
      tableData: [],
      options: {},
      defaultForm: {},
      isFirstLoad: true,
      form: {
        id: undefined
      }
    };
  },
  methods: {
    async getData(page = null) {
      try {
        this.$loader(true);
        if (page) this.params.page = page;
        const { data, meta } = await index(this.params);
        this.tableData = data;
        this.pagination = meta;
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    },
    async getOption() {
      try {
        const opts = {};
        let results = await Promise.all(
          Object.values(options).map(option => option.func(option.params))
        );
        results = results.map(item => item.data);
        Object.keys(options).forEach((item, key) => {
          opts[item] = results[key];
        });
        this.options = { ...opts };
      } catch (error) {
        console.log(error);
      }
    },
    showDialogForm(mode, data = null) {
      if (mode == "edit") {
        this.editing = true;
        for (let field in this.form) {
          this.form[field] = data[field];
        }
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm));
        this.form.id = undefined;
        this.editing = false;
      }
      this.showDialog = true;
    },
    reset() {
      this.params = { ...this.defaultParams };
      this.getData();
    },
    getQueryParams() {
      this.params = { ...this.defaultParams };
    },
    handleSort({ sortBy, sortDesc }) {
      if (sortBy) {
        this.isFirstLoad = false;
        this.params.sortBy = `${sortBy}${sortDesc ? ":desc" : ""}`;
      }
      if (!this.isFirstLoad) this.getData(1);
    },
    async exportData() {
      this.$loader(true);
      const { page, perPage, ...params } = this.params;
      const data = await exportData(params);
      const url = URL.createObjectURL(
        new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.model || "export"}.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.$loader(false);
    }
  },

  created() {
    this.defaultForm = JSON.parse(JSON.stringify(this.form));
    if (options) this.getOption();
    this.getQueryParams();
    this.getData();
  }
});

export default indexMixin;
