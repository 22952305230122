const dataTableMixin = destroy => ({
  data() {
    return {
      options: {}
    };
  },
  props: ["tableData", "form", "pagination"],
  computed: {
    headers() {
      return [];
    }
  },
  methods: {
    getHeaders() {
      return this.headers;
    },
    async handleDelete(id) {
      try {
        const res = await this.$confirm(
          "Bạn có chắc chắn muốn xóa bản ghi này?",
          {
            title: "Xác nhận xóa",
            buttonTrueText: "Đồng ý",
            buttonFalseText: "Hủy"
          }
        );
        if (res) {
          await destroy(id);
          this.$snackbar("Xóa dữ liệu thành công", "success");
          this.$emit("deleted");
        }
      } catch (error) {
        console.log(error);
      }
    },
    sort() {
      const { sortBy, sortDesc } = this.options;
      console.log(sortBy, sortDesc);
      this.$emit("sorted", { sortBy: sortBy[0], sortDesc: sortDesc[0] });
    }
  },
  watch: {
    options: {
      handler() {
        this.sort();
      },
      deep: true
    }
  }
});

export default dataTableMixin;
